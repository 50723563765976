import firebase from 'firebase';
const config = {
    apiKey: "AIzaSyDSGmm02Y85YfaIWYLMspZg11gjZeMK-BY",
    authDomain: "quiz-public.firebaseapp.com",
    databaseURL: "https://quiz-public.firebaseio.com",
    projectId: "quiz-public",
    storageBucket: "quiz-public.appspot.com",
    messagingSenderId: "970137871495",
    appId: "1:970137871495:web:d596634b263ce12f024422"
};

var configProd = {
    apiKey: "AIzaSyDN4wsMsMdGfPMZt_L5cfCSj7RIGtkLSro",
    authDomain: "quiz-public-30d33.firebaseapp.com",
    databaseURL: "https://quiz-public-30d33.firebaseio.com",
    projectId: "quiz-public-30d33",
    storageBucket: "quiz-public-30d33.appspot.com",
    messagingSenderId: "878614265981",
    appId: "1:878614265981:web:58f845a03a6a6f04643114"
};

export const configUsed = configProd;
var fire = firebase.initializeApp(configUsed);
export default fire;    